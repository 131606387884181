import request from '@/utils/request';

// 登录
export function Login(data) {
    return request({
        url: '/wisc/youth/login',
        method: 'POST',
        data
    });
}

