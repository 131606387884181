
<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <a class="activation-box" href="https://jingmei-web-weishi-child.oss-cn-chengdu.aliyuncs.com/helpfile/help.docx" target="_blank"  style="cursor: pointer">
        <img src="/images/help.png" alt=""  style="cursor: pointer">
        <span  style="cursor: pointer">系统指引</span>
      </a>
      <div class="activation-box" @click="goActivation" style="cursor: pointer">
        <img src="/images/activation-icon.png" alt=""  style="cursor: pointer">
        <span  style="cursor: pointer">新主试激活</span>
      </div>
    </div>
    <div class="main-body">
      <div class="login-container">
        <div class="login-box">
          <div class="name">用户登录</div>
          <div class="small-name">韦氏儿童智力量表第四版中文版计分系统</div>
          <a-form-model ref="form" :model="formModel"  :rules="formRules" class="login-form" autoComplete="off">
            <a-form-model-item label="" prop="account">
              <a-input  placeholder="账号" class="cu-input cu-input-w" v-model="formModel.account" allowClear />
            </a-form-model-item>
            <a-form-model-item label="" prop="pwd">
              <a-input type="password" placeholder="密码" class="cu-input cu-input-w" v-model="formModel.pwd" allowClear />
            </a-form-model-item>
            <a-form-model-item label="" prop="verification_code">
              <div style="display: flex">
                <a-input placeholder="验证码" class="cu-input cu-input-w" v-model="formModel.verification_code" allowClear />
                <img @click="refreshCode" :src="codeImgUrl" alt="点击重新获取" style="margin-left: 20px;cursor: pointer">
              </div>
            </a-form-model-item>
          </a-form-model>
          <div class="btn" @click="login">登录</div>
          <div class="more">
            <span @click="goForgetPassword">忘记密码?</span>
            <i></i>
            <span @click="goActivation">新主试激活</span>
          </div>
        </div>
        <div style="display: flex;margin-top: 40px;padding: 0 10px">
<!--          <div style="flex: 1;display: flex;justify-content: flex-start"><img style="width: 160px" src="/images/login-bottom-1.png" alt=""></div>-->
<!--          <div style="flex: 1;display: flex;justify-content: flex-end"><img style="width: 160px" src="/images/login-bottom-2.png" alt=""></div>-->
          <img src="/images/login-bottom.png" style="width: 100%" alt="">
        </div>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
  import * as Api from './api';
  import {GetInfo} from '../../commonApi';
  import { uuid } from 'vue-uuid';
  import {message} from "ant-design-vue";

  export default {
    data() {
      return {
        formModel: {
          "account":"",
          "pwd":"",
          "uuid":"",
          "verification_code":""
        },
        formRules: {
          account: [
            { required: true, message: '请输入账号', trigger: 'change' },
          ],
          pwd: [
            { required: true, message: '请输入密码', trigger: 'change' },
          ],
          verification_code: [
            { required: true, message: '请输入验证码', trigger: 'change' },
          ],
        },
        codeImgUrl: ''
      };
    },
    mounted() {
      this.formModel.uuid = uuid.v1();
      this.codeImgUrl = process.env.VUE_APP_API + '/wisc/login/getVerifyCode?uuid=' + this.formModel.uuid
    },
    methods: {
      async login() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            const res = await Api.Login(this.formModel);
            if (res && res.code == 0) {
              window.localStorage.setItem('login_code', res.login_code);
              window.localStorage.setItem('login_time', new Date());
              message.success({ content: res.message});
              this.getInfo()

            }
          }
        });
      },
      async getInfo() {
        const res = await GetInfo({});
        if (res && res.code == 0) {
          window.localStorage.setItem('abas_user', JSON.stringify(res.chiefExaminer))
          setTimeout(()=> {
            this.$router.push({ path: "/" });
          }, 1500);
        }
      },
      goForgetPassword() {
        this.$router.push({path: '/forget-password'})
      },
      goActivation() {
        this.$router.push({path: '/activation'})
      },
      refreshCode() {
        this.formModel.uuid = uuid.v1();
        this.codeImgUrl = process.env.VUE_APP_API + '/wisc/youth/login/getVerifyCode?uuid=' + this.formModel.uuid;
      }
    }
  };
</script>

<style scoped>
  .login-box .more {
    font-size: 12px;
    font-weight: 500;
    color: #7E8589;
    line-height: 40px;
  }
  .login-box .more {
    margin-top: 10px;
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .login-box .more span {
    flex: 1;
  }
  .login-box .more span:first-child {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
  }
  .login-box .more i {
    height: 10px;
    border-right: 1px solid #999999;
    width: 10px;
  }
  .login-box .more span:last-child {
    display: flex;
    align-items:flex-start ;
    padding-left: 10px;
  }
  .login-form {
    margin-top: 20px;
  }

  .login-box .name {
    font-size: 26px;
    font-weight: bold;
    color: #3E4144;
    line-height: 40px;
  }
  .login-box .small-name {
    font-size: 14px;
    font-weight: 400;
    color: #4A4E51;
    line-height: 40px;
  }
  .login-box .btn {
    background: #832C75;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
  }

  .name-box {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    flex: 1;
    padding-left: 30px;
  }
  .login-container {
    width: 400px;
    margin-right: 200px;
  }
  .login-box {
    background: #FFFFFF;
    box-shadow: 0px 23px 25px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 45px;
  }
</style>
